const DataUsageAgreementInstructions = (props) => {
    return (
        <div class="dua-instructions" style={{ 'margin-bottom': '0.5em', 'margin-top': '0.5em', fontSize: '13px' }} className="tab">
            <h4>Adding a Data Use Agreement (DUA) for the Cardiac Atlas Project (CAP) Database (Updated 2-2025)</h4>        
            <section class="overview">
                <h5><u>Overview</u></h5>
                <p style={{ 'margin-bottom': '0.5em', 'margin-top': '0.5em'}}>
                All non-UCSD and RCHSD Users of the Cardiac Atlas Database (<a href="https://capchd.ucsd.edu" target="_blank">capchd.ucsd.edu</a>)
                must have prior authorization in order to view, upload and/or download the data. This authorization consists of a fully executed DUA
                (signed by both parties) and uploaded to the UCSD Kuali system as an Unfunded Agreement with UCSD. The CAP database webpage
                allows for tracking current DUA agreements, with instructions for adding new agreements. Once a DUA is executed, the user 
                can be added to the database, allowing for access via that DUA.
                </p>
            </section>

            <section class="request-for-dua">
                <h5><u>Request for DUA</u></h5>

                <p>
                When a prospective user requests access to the CAP database, UCSD provides them with a pre-approved and current DUA template(s).
                UCSD should e-mail the prospective user the appropriate DUA template, using a template e-mail. If the request is from a 
                <span style={{ color: "red", fontWeight: 700 }}> domestic user</span>, use this template e-mail (and attach both PDF and Word DUAs):
                </p>

                <ul className='tab'>
                    <p>
                    Thank you for your interest in the Cardiac Atlas Congenital Heart Disease Database (<a href="https://capchd.ucsd.edu" target="_blank">capchd.ucsd.edu</a>).
                    In order to obtain access to the database, a fully executed Data Use Agreement (DUA) must be in place between UCSD and the outside entity.
                    Note that this database can only be used for research purposes, not commercial applications.
                    </p>

                    <p>
                    Please find 2 template DUAs attached for review. The fillable PDF version can be used if no changes are requested to the DUA wording. 
                    If that is the case, fill out the entity information, the short description of use, and have an approved signatory sign the document. 
                    Scan and return. If changes are requested, please fill out the Word version, tracking all changes, for review by the UCSD legal team. 
                    A final approved/signed version will be required after any negotiations are complete.
                    </p>
                </ul>

                <p>If the user is <span style={{ color: "red", fontWeight: 700 }}> non-domestic</span>, they will need to use the Word version only, so the e-mail is slightly different:</p>

                <ul className='tab'>
                    <p>
                    Thank you for your interest in the Cardiac Atlas Congenital Heart Disease Database (<a href="https://capchd.ucsd.edu" target="_blank">capchd.ucsd.edu</a>).
                    In order to obtain access to the database, a fully executed Data Use Agreement (DUA) must be in place between UCSD and the outside entity.
                    Note that this database can only be used for research purposes, not commercial applications.
                    </p>

                    <p>
                    Please find the template DUAs attached for review. Fill out the entity information, the short description of use, and have an approved signatory sign 
                    the document. Scan and return. If changes are requested, please fill out the Word version form, tracking all changes, for review by the UCSD legal team. 
                    A final approved/signed version will be required after any negotiations are complete.
                    </p>
                </ul>
            </section>

            <section class="dua-template">
                <h5><u>DUA template</u></h5>
                <p>
                There are 2 types of templates: (1) a non-editable PDF for streamlined approval meeting requirements below (“Non-negotiable fillable CAP DUA 1-29-25”). 
                The second template (2) is an editable Word file “Editable CAP DUA 1-29-25”. This is the starting point for DUAs from foreign institutions or those requiring edits. 
                Both of these files are accessible below. UCSD will e-mail these templates as described above. The signer must be an institutional signatory, i.e. someone who can sign 
                a contract for the institution (not a student or typically not a PI).
                </p>

                <p>In order to use the streamlined PDF template, all 4 of the following requirements must be met:</p>
                <ol className='tab' style={{'list-style-type': 'disc'}}>
                    <li>
                    DUAs with domestic academic institutions only
                        <ul style={{'list-style-type': 'circle', marginLeft: "4rem"}}>
                            <li>Foreign entities will need to go through Kuali. This is due to compliance concerns</li>
                        </ul>
                    </li>
                    <li>
                    DUAs must transfer the 2 datasets of human subjects data for the Cardiac Atlas Project only
                        <ul style={{'list-style-type': 'circle', marginLeft: "4rem"}}>
                            <li>No tangible materials, such as biospecimens, equipment, software, etc. can be transferred</li>
                        </ul>
                    </li>
                    <li>The template is used as-is with no additional edits or negotiation</li>
                    <li>No known COI conflicts between the counterparties and UCSD personnel</li>
                </ol>

                <p>
                If a domestic user signs and returns the PDF version without requesting changes, then the DUA is considered executed and does not 
                need a Kuali record. The new user/password can be added to the database by an admin. The executed DUA and other information should 
                be added here. Finally, the signed PDF should be sent to the Kuali project manager (Sierra Jenkins, <a href="mailto:ssjenkins@ucsd.edu">ssjenkins@ucsd.edu</a>) 
                for OCGA recording. Another contact (manager) at OCGA is Lisa Gu, <a href="mailto:lqgu@ucsd.edu">lqgu@ucsd.edu</a>.
                </p>

                <p>
                If the DUA request does not meet all of the streamline requirements above, it will need to be entered as a new Kuali request with UCSD legal review: 
                </p>
            </section>
            
            <section class="kuali-request">
                <h5><u>Kuali Request</u></h5>

                <p>
                After the outside user has completed the DUA template using the Word file template (and tracked changes), a new Kuali record request is generated by 
                UCSD personnel. Specific instructions for the Kuali request:
                </p>

                <p>
                1) The new Kuali request can be created by copying/replicating an existing document, i.e. #62008. This Kuali record is under Jeff Omens as of 2-2025. 
                To find all of Omens DUAs, Under “Common Tasks”, scroll to bottom of “All my Proposals”. From that menu you can copy an existing DUA.
                </p>

                <p>
                2) Some updates will be required in a few of the tabs:
                </p>
                <ol className='tab'>
                    <li>Basics>Sponsor: Lookup outside University/entity</li>
                    <li>Key Personnel? Compliance Questionnaire, answer all</li>
                    <li>Questionnaire>Unfunded Agreement>Full name/e-mail: Enter requesting person</li>
                    <li>Compliance: Add Export Control Pending for foreign entities</li>
                    <li>Attachments: Add DUA template, signed (or unsigned for wording changes)</li>
                    <li>Supplemental Information>COA Financial Unit 1000174 (Bioeng)</li>
                    <li>SUBMIT with Warnings OK</li>
                </ol>

                <p>
                3) After the DUA has been submitted via Kuali, send an e-mail to our project manager, Sierra Jenkins, <a href="mailto:ssjenkins@ucsd.edu">ssjenkins@ucsd.edu</a>, 
                letting her know that a new DUA request has been uploaded.
                </p>

                <p>The main Kuali Certification for this process is recorded as:</p>
                <ul className='tab'>
                    <li><strong>Document Number:</strong> 1246516</li>
                    <li><strong>Proposal Number:</strong> 62008</li>
                    <li><strong>Proposal Title:</strong> Data Use Agreement for The Cardiac Atlas Project</li>
                    <li><strong>Principal Investigator:</strong> Jeffrey Omens</li>
                    <li><strong>Lead Unit:</strong> 000239 - Bioengineering</li>
                    <li><strong>Sponsor:</strong> 6778 - UC SAN DIEGO</li>
                </ul>
            </section>
            <section class="approval">
                <h5><u>Approval</u></h5>
                <p>
                Once UCSD has approved the Kuali project request, the DUA is “fully executed,” and the new user/password can be added to the
                database by an admin. The executed request and other information should be added here.
                </p>
            </section>
        </div>
    )
}

export default DataUsageAgreementInstructions;  