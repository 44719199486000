import { SaveAlt } from "@material-ui/icons";
import Button from '@mui/material/Button';

const handleDownloadPDF = () => {
  const template_path = 'doc/Non-negotiable_fillable_CAP_DUA_1-29-25.pdf';
  const link = document.createElement('a');
  link.href = template_path;
  link.download = 'Non-negotiable fillable CAP DUA.pdf'; // Specify a filename for download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const handleDownloadWord = () => {
  const template_path = 'doc/Editable_CAP_DUA_1-29-25.docx';
  const link = document.createElement('a');
  link.href = template_path;
  link.download = 'Editable CAP DUA.docx'; // Specify a filename for download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const DUATemplateDownload = () => {
  return (
    <div style={{
      backgroundColor: 'rgb(235, 239, 241)',
      height: 'auto',
      width: '100%',
      paddingRight: 60,
      display: "flex",
      "flex-direction": "column",
      "align-items": "left",
    }}>
      <h4 style={{ fontSize: "2rem" }}><b>Download Data Use Agreement Templates</b></h4>
      <div style={{ display: "flex", columnGap: "1.5rem" }}>
        <Button id="download-table-csv" variant="outlined" color="inherit" size="small" startIcon={<SaveAlt />} onClick={handleDownloadPDF}>
          Download PDF
        </Button>
        <Button id="download-table-csv" variant="outlined" color="inherit" size="small" startIcon={<SaveAlt />} onClick={handleDownloadWord}>
          Download Word
        </Button>
      </div>
    </div>
  );
}

export default DUATemplateDownload;